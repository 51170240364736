import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import EditEnrollmentModal from "./EditEnrollmentModal";
import {
  HAS_LEGAL_EQUIVALENCE,
  HAS_LEGAL_PROOF,
} from "../../../utils/constants";
import { useContext } from "react";
import { AuthContext } from "../../../provider/AuthProvider";

function DataAccordion({
  id,
  index,
  isDisabled,
  schoolBondRequests,
  setSchoolBondRequests,
  schoolBondRequestsData,
  setSchoolBondRequestsData,
  academicYear,
  school,
  schoolId,
  course,
  courseTechnical,
  courseEducationalSubsystem,
  courseEducationalCycle,
  studentEnrollmentStatus,
  studentClass,
  studentClassValue,
  classModality,
  classShift,
  classLetter,
  classFullName,
  schoolBondResult,
  schoolBondResultValue,
  courseCompletionAverage,
  hasLegalEquivalence,
  hasLegalProof,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  const deleteData = () => {
    const newSchoolBondRequest = schoolBondRequests.filter(
      (_, i) => i !== index
    );
    const newSchoolBondRequestsData = schoolBondRequestsData.filter(
      (_, i) => i !== index
    );
    setSchoolBondRequests(newSchoolBondRequest);
    setSchoolBondRequestsData(newSchoolBondRequestsData);
    onClose();
  };

  return (
    <Accordion allowToggle>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box
              as="span"
              flex="1"
              textAlign="left"
              fontWeight="bold"
              fontSize="xl"
            >
              Ano Lectivo {academicYear}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel py={4}>
          <Flex justifyContent="space-between">
            <Stack spacing={8}>
              <Box>
                <Text
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="sm"
                  mb={2}
                >
                  Escola
                </Text>
                {school}
              </Box>
              <Box>
                <Text
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="sm"
                  mb={2}
                >
                  Curso
                </Text>
                {course}
              </Box>
              <Box>
                <Text
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="sm"
                  mb={2}
                >
                  Área de Formação do INFQE
                </Text>
                {courseTechnical}
              </Box>
              <Box>
                <Text
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="sm"
                  mb={2}
                >
                  Subsistema de Ensino
                </Text>
                {courseEducationalSubsystem}
              </Box>
              <Box>
                <Text
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="sm"
                  mb={2}
                >
                  Ciclo de Ensino
                </Text>
                {courseEducationalCycle}
              </Box>
              <Box>
                <Text
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="sm"
                  mb={2}
                >
                  Classe
                </Text>
                {studentClass}
                <Box mt={1}>
                  {hasLegalEquivalence && (
                    <Text fontSize="sm">({HAS_LEGAL_EQUIVALENCE})</Text>
                  )}
                  {hasLegalProof && (
                    <Text fontSize="sm">({HAS_LEGAL_PROOF})</Text>
                  )}
                </Box>
              </Box>
              <Box>
                <Text
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="sm"
                  mb={2}
                >
                  Modalidade
                </Text>
                {classModality}
              </Box>
              <Box>
                <Text
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="sm"
                  mb={2}
                >
                  Turno
                </Text>
                {classShift}
              </Box>
              <Box>
                <Text
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="sm"
                  mb={2}
                >
                  Letra da Turma
                </Text>
                {classLetter}
              </Box>
              <Box>
                <Text
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="sm"
                  mb={2}
                >
                  Turma
                </Text>
                {classFullName}
              </Box>
              <Box>
                <Text
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="sm"
                  mb={2}
                >
                  Estado da matrícula
                </Text>
                {studentEnrollmentStatus}
              </Box>
              {id && (
                <>
                  {schoolBondResult && (
                    <Box>
                      <Text
                        textTransform="uppercase"
                        fontWeight="bold"
                        fontSize="sm"
                        mb={2}
                      >
                        Resultado
                      </Text>
                      {schoolBondResult}
                    </Box>
                  )}
                  {(studentClassValue === 18 || studentClassValue === 22) &&
                    schoolBondResultValue &&
                    schoolBondResultValue === 1 && (
                      <Box>
                        <Text
                          textTransform="uppercase"
                          fontWeight="bold"
                          fontSize="sm"
                          mb={2}
                        >
                          Média de conclusão de curso
                        </Text>
                        {courseCompletionAverage?.toString().replace(".", ",")}
                      </Box>
                    )}
                </>
              )}
            </Stack>
            {!isDisabled &&
              (user?.schoolId && user?.schoolId !== schoolId ? (
                <></>
              ) : (
                <Box>
                  <EditEnrollmentModal
                    id={id}
                    index={index}
                    schoolBondRequests={schoolBondRequests}
                    setSchoolBondRequests={setSchoolBondRequests}
                    schoolBondRequestsData={schoolBondRequestsData}
                    setSchoolBondRequestsData={setSchoolBondRequestsData}
                  />
                  <Button variant="link" onClick={onOpen} ml={4}>
                    Excluir
                  </Button>
                  <AlertDialog isOpen={isOpen} onClose={onClose}>
                    <AlertDialogOverlay>
                      <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                          Excluir Dados Escolares
                        </AlertDialogHeader>

                        <AlertDialogBody>
                          Tem a certeza que pretende excluir os dados escolares?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                          <Button onClick={deleteData} mr={4}>
                            Excluir
                          </Button>
                          <Button variant="link" onClick={onClose}>
                            Cancelar
                          </Button>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialogOverlay>
                  </AlertDialog>
                </Box>
              ))}
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

export default DataAccordion;
