import { Box, Button, Heading } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import api from "../../../utils/api";
import { FormUploadList } from "./uploadListForm";
import { Toast } from "../../../components/Toast";
import { Navigate } from "react-router-dom";
import { useRef, useState } from "react";
import { formStyles } from "../../../utils/styles";
import { UPLOAD_FILE } from "../../../utils/endpoints";
import { ListErrorsUploadList } from "./errorList";

export function UploadList() {
  const { addToast } = Toast();
  const fileRef = useRef();
  const {
    // register,
    // handleSubmit,
    // formState: { errors },

    // unregister,

    // eslint-disable-next-line no-unused-vars
    setValue,
  } = useForm({
    resolver: zodResolver(),
  });
  const [errorsExcell, setErrorsExcell] = useState([]);
  const [isClicked, setIsClicked] = useState(true);

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      const data = new FormData();
      data.append("File", fileRef.current.files[0]);
      data.append("FileName", "escola.xlsx");
      data.append(
        "ContentType",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );

      const response = await api.post(UPLOAD_FILE, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.errorList.length > 0) {
        setErrorsExcell(response.data.errorList);
      }
      addToast({ title: "Lista carregada com sucesso", status: "success" });
      setIsClicked(false);
    } catch (e) {
      let errors = e.response.data.errors;
      for (let err in errors) {
        addToast({ title: errors[err], status: "error" });
      }
    }
  };

  return (
    <>
      {isClicked ? (
        <>
          <Heading mb={5} mt={5}>
            Carregar Lista
          </Heading>
          <form onSubmit={onSubmit}>
            <Box sx={formStyles}>
              <FormUploadList fileRef={fileRef} />
            </Box>

            <Box>
              <Button type="submit" mt={5}>
                Carregar Lista
              </Button>
            </Box>
          </form>
        </>
      ) : errorsExcell.length > 0 ? (
        <ListErrorsUploadList data={errorsExcell} />
      ) : (
        <Navigate to="/escolas" />
      )}
    </>
  );
}
