import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { BsQuestionCircle } from "react-icons/bs";
import { DIALOG_MESSAGE } from "../../../utils/constants";

function Delete({ deleteRole }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex>
      <Button mr={2} variant="link" onClick={onOpen}>
        Excluir perfil
      </Button>
      <Tooltip
        hasArrow
        label="Esta acção exclui, de forma permanente, este perfil do sistema"
      >
        <Flex alignItems="center">
          <BsQuestionCircle />
        </Flex>
      </Tooltip>

      <AlertDialog isOpen={isOpen} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Excluir perfil
            </AlertDialogHeader>

            <AlertDialogBody>{DIALOG_MESSAGE}</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={deleteRole} mr={4}>
                Excluir
              </Button>
              <Button variant="link" onClick={onClose}>
                Cancelar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
}

export default Delete;
