import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Button,
  Box,
  Heading,
  Text,
  FormErrorMessage,
  FormHelperText,
  FormControl,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Controller } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import Label from "../../../../components/Label";
import {
  REQUIRED_FIELD,
  TYPEDOCUMENTBI,
  regexPattern,
} from "../../../../utils/constants";
import { TEACHER_URL } from "../../../../utils/endpoints";
import api from "../../../../utils/api";
import { Toast } from "../../../../components/Toast";
import { getIdentificationDocumentType } from "../../../../services/getIdentificationDocumentType";
import { AuthContext } from "../../../../provider/AuthProvider";

export default function ModalConfirmTeacher({ setIsOpen, isOpen, title }) {
  const [typeDocumentOptions, setTypeDocumentOptions] = useState([]);
  const navigate = useNavigate();
  const { addToast } = Toast();
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  const {
    control,
    watch,
    register,
    formState: { errors },
  } = useForm();

  const typeDocument = watch("typeDocument");
  const documentNumber = watch("documentNumber");

  async function getTypeDocument() {
    getIdentificationDocumentType()
      .then((res) => {
        setTypeDocumentOptions(res);
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  useEffect(() => {
    getTypeDocument();
  }, []);

  const handleClickNext = async () => {
    if (!typeDocument || !documentNumber) {
      addToast({ title: REQUIRED_FIELD, status: "error" });
    } else {
      if (typeDocument?.value === 1 && !regexPattern.exec(documentNumber)) {
        addToast({
          title: "Formato do nº de documento de identificação inválido",
          status: "error",
        });
      } else {
        const response = await api.get(
          `${TEACHER_URL}?DocumentNumber=${documentNumber}&TypeDocumentId=${typeDocument?.value}`
        );
        if (response.data?.result.length > 0) {
          if (user?.schoolId) {
            navigate(
              `/escolas/gerir/${user?.schoolId}/docentes/${response.data.result[0].id}`
            );
          } else {
            navigate(`/docentes/${response.data.result[0].id}`);
          }
        } else {
          if (user?.schoolId) {
            navigate(
              `/escolas/gerir/${
                user?.schoolId
              }/docentes/cadastrar?typeDocumentValue=${
                watch("typeDocument").value
              }&typeDocument=${
                watch("typeDocument").label
              }&documentNumber=${watch("documentNumber")}`
            );
          } else {
            navigate(
              `/docentes/cadastrar?typeDocumentValue=${
                watch("typeDocument").value
              }&typeDocument=${
                watch("typeDocument").label
              }&documentNumber=${watch("documentNumber")}`
            );
          }
        }
      }
    }
  };

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <Box m={6}>
          <Heading size="lg">{title}</Heading>
        </Box>

        <ModalCloseButton />
        <ModalBody pb={6}>
          <Box>
            <Label
              title="Tipo de Documento de Identificação"
              isRequired={true}
            />
            <Controller
              control={control}
              name="typeDocument"
              render={({ field: { onChange, value } }) => (
                <Select
                  onChange={onChange}
                  name="colors"
                  value={value || ""}
                  options={typeDocumentOptions}
                  className="basic-multi-select"
                  classNamePrefix="Seleccionar"
                  placeholder="Seleccionar"
                />
              )}
            />
            <FormErrorMessage>
              {errors?.typeDocument?.message && <>{REQUIRED_FIELD}</>}
            </FormErrorMessage>
          </Box>
          <FormControl mt={7}>
            <Label title="Nº de Documento de Identificação" isRequired={true} />
            <Input
              {...register("documentNumber")}
              placeholder="Número de Documento de Identificação"
            />

            {typeDocument?.label === TYPEDOCUMENTBI && (
              <FormHelperText>Exemplo: 123456789AA123</FormHelperText>
            )}

            <FormErrorMessage>
              {errors?.documentNumber && <>{REQUIRED_FIELD}</>}
            </FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="link"
            mr={3}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Cancelar
          </Button>
          <Button onClick={handleClickNext}>Seguinte</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
