import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
} from "@chakra-ui/react";
import Label from "../../components/Label";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { REQUIRED_FIELD } from "../../utils/constants";
import { useEffect } from "react";
import { RESET_PASSWORD } from "../../utils/endpoints";
import { Toast } from "../../components/Toast";
import api from "../../utils/api";

function ResetPassword({ isOpen, onClose }) {
  const { addToast } = Toast();

  const schema = z.object({
    email: z.string().nonempty(REQUIRED_FIELD).email("Email inválido"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    if (isOpen) {
      reset({
        email: "",
      });
    }
  }, [isOpen]);

  const resetPassword = (data) => {
    api
      .patch(RESET_PASSWORD, {
        email: data.email,
      })
      .then(() => {
        addToast({
          title: "Senha alterada com sucesso",
          status: "success",
        });
        onClose();
      })
      .catch((error) => {
        let errors = error.response.data.errors;
        for (let err in errors) {
          addToast({ title: errors[err], status: "error" });
        }
      });
  };

  return (
    <Flex justifyContent="end">
      <AlertDialog isOpen={isOpen} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Esqueci-me da senha
            </AlertDialogHeader>

            <Box as="form" onSubmit={handleSubmit(resetPassword)}>
              <AlertDialogBody>
                <FormControl isInvalid={!!errors.email}>
                  <Label title="Insira o seu email" />
                  <Input type="email" {...register("email")} />
                  <FormErrorMessage>
                    {errors.email && errors.email.message}
                  </FormErrorMessage>
                </FormControl>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button type="submit" mr={3}>
                  Redefinir senha
                </Button>
                <Button variant="secondary" onClick={onClose}>
                  Cancelar
                </Button>
              </AlertDialogFooter>
            </Box>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
}

export default ResetPassword;
