import { z } from "zod";
import { REQUIRED_FIELD } from "../../../utils/constants";

export const schema = z.object({
  name: z.string().nonempty(REQUIRED_FIELD),
  typeDocument: z.string().nonempty(REQUIRED_FIELD),
  documentNumber: z.string().nonempty(REQUIRED_FIELD),
  agentNumber: z.number().min(1, { message: REQUIRED_FIELD }),
  birthDate: z.date().refine(
    (value) => {
      const inputDate = new Date(value);
      const currentDate = new Date();
      const eighteenYearsAgo = new Date(
        currentDate.getFullYear() - 18,
        currentDate.getMonth(),
        currentDate.getDate()
      );

      if (inputDate <= eighteenYearsAgo) {
        return true;
      } else {
        return false;
      }
    },
    {
      message: "O docente deve ter pelo menos 18 anos de idade",
    }
  ),
  gender: z.string().nonempty(REQUIRED_FIELD),
  nationality: z.object({
    value: z.number(),
    label: z.string(),
  }),
  academicQualification: z.object({
    value: z.number(),
    label: z.string(),
  }),
  trainingArea: z.object({
    value: z.number(),
    label: z.string(),
  }),
  pedagogicalTraining: z.string().nonempty(REQUIRED_FIELD),
});
