import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import Select from "react-select";
import { useEffect, useState } from "react";
import { NO_OPTIONS_MESSAGE } from "../../../utils/constants";

export default function ModalAssociate({
  setIsOpen,
  isOpen,
  title,
  setDataTable,
  dataTable,
  schools,
}) {
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (isOpen) {
      setSelectedOptions([]);
      setOptions(
        schools
          .filter(
            (obj2) =>
              !dataTable.some(
                (obj1) => obj1.academicYearForSchool.school.id === obj2.id
              )
          )
          .map((i) => ({
            value: i.id,
            label: i.name,
            code: i.code,
          }))
      );
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Select
            isMulti={true}
            placeholder="Seleccionar"
            options={options}
            onChange={(values) => {
              setSelectedOptions(values);
            }}
            noOptionsMessage={NO_OPTIONS_MESSAGE}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              const modifiedOptions = selectedOptions.map((option) => ({
                academicYearForSchool: {
                  school: {
                    id: option.value,
                    code: option.code,
                    name: option.label,
                  },
                },
                applications: null,
                capacity: null,
              }));
              setDataTable([...dataTable, ...modifiedOptions]);
              setIsOpen(false);
            }}
            mr={4}
          >
            Associar
          </Button>
          <Button
            variant="link"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
