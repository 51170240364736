import { MdAddCircle, MdCloudDownload, MdCloudUpload } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ACADEMIC_YEAR_URL,
  DOWNLOAD_TEMPLATE_TEACHER,
  SCHOOL_URL,
} from "../../../utils/endpoints";
import { downloadExcelFile } from "../../../utils/downloadExcelFile";
import { useContext, useEffect, useState } from "react";
import { Toast } from "../../../components/Toast";
import { AuthContext } from "../../../provider/AuthProvider";
import api from "../../../utils/api";
import Label from "../../../components/Label";
import Select from "react-select";
import { NO_OPTIONS_MESSAGE } from "../../../utils/constants";

export default function ButtonGroupTeacher({ setIsOpen }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [school, setSchool] = useState();
  const [academicYear, setAcademicYear] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [academicYearOptions, setAcademicYearOptions] = useState([]);
  const { addToast } = Toast();

  const { auth } = useContext(AuthContext);
  const user = auth.user;
  const { id } = useParams();

  async function getSchools() {
    api
      .get(`${SCHOOL_URL}?isValidated=true&isActive=true`)
      .then((res) => {
        const data = res.data.result;
        setSchoolOptions(
          data.map((i) => ({
            value: i.id,
            label: i.name,
          }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getAcademicYears() {
    api
      .get(ACADEMIC_YEAR_URL)
      .then((res) => {
        const data = res.data.result;
        setAcademicYearOptions(
          data
            .filter((i) => i.isActive)
            .map((i) => ({
              value: i.id,
              label: i.year,
              isBaseYear: i.isBaseYear,
            }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  useEffect(() => {
    if (!user?.schoolId) {
      setSchool();
    }
    setAcademicYear();
    getSchools();
    getAcademicYears();
  }, [isOpen]);

  useEffect(() => {
    if (school?.value && academicYear?.value) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [school, academicYear]);

  useEffect(() => {
    if (user?.schoolId || id) {
      setSchool(
        schoolOptions.filter(
          (item) => item.value === user?.schoolId || item.value == id
        )[0]
      );
    }
  }, [schoolOptions]);

  const downloadTemplate = async () => {
    const apiUrl = `${DOWNLOAD_TEMPLATE_TEACHER}?SchoolId=${school?.value}&AcademicYearId=${academicYear?.value}`;
    downloadExcelFile(apiUrl, "Template Docentes.xlsx");
  };

  return (
    <HStack>
      <Button
        rightIcon={<MdAddCircle />}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Cadastrar
      </Button>
      <Link to="/docentes/carregar-lista">
        <Button rightIcon={<MdCloudUpload />}>Carregar lista</Button>
      </Link>
      <Button rightIcon={<MdCloudDownload />} onClick={onOpen}>
        Descarregar Template
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Descarregar Template</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Stack spacing={5}>
              <Box>
                <Label title="Escola" isRequired />
                <Select
                  placeholder="Seleccionar"
                  value={
                    user?.schoolId
                      ? schoolOptions.find(
                          (option) => option.value === school?.value
                        )
                      : undefined
                  }
                  options={schoolOptions}
                  onChange={(value) => {
                    setSchool(value);
                  }}
                  isDisabled={user?.schoolId}
                  noOptionsMessage={NO_OPTIONS_MESSAGE}
                />
              </Box>
              <Box>
                <Label title="Ano Lectivo" isRequired />
                <Select
                  placeholder="Seleccionar"
                  options={academicYearOptions}
                  onChange={(value) => {
                    setAcademicYear(value);
                  }}
                  noOptionsMessage={NO_OPTIONS_MESSAGE}
                />
              </Box>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} isDisabled={isDisabled} onClick={downloadTemplate}>
              Descarregar
            </Button>
            <Button variant="link" onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </HStack>
  );
}
