import { Box, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Delete from "../components/Delete";
import { useContext } from "react";
import { AuthContext } from "../../../provider/AuthProvider";

function FormButtons({
  isDisabled,
  setIsDisabled,
  id,
  createRole,
  cancelEdit,
  deleteRole,
}) {
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  return (
    <>
      {isDisabled ? (
        id ? (
          <>
            {user.permissions.includes("EditRole") && (
              <Box mb={4}>
                <Button mr={4} onClick={() => setIsDisabled(false)}>
                  Editar Perfil
                </Button>
              </Box>
            )}
            {user.permissions.includes("DeleteRole") && (
              <Delete deleteRole={deleteRole} />
            )}
          </>
        ) : (
          <>
            <Button mr={4} onClick={createRole}>
              Confirmar
            </Button>
            <Button variant="link" onClick={() => setIsDisabled(false)}>
              Voltar a editar
            </Button>
          </>
        )
      ) : id ? (
        <>
          <Button type="submit" mr={4}>
            Guardar
          </Button>
          <Button variant="link" onClick={cancelEdit}>
            Cancelar
          </Button>
        </>
      ) : (
        <>
          <Button type="submit" mr={4}>
            Cadastrar Perfil
          </Button>
          <Button variant="link" onClick={() => navigate("/perfis")}>
            Cancelar
          </Button>
        </>
      )}
    </>
  );
}

export default FormButtons;
