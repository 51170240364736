import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Input,
  Stack,
  FormControl,
  FormErrorMessage,
  HStack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import Label from "../../../../components/Label";
import api from "../../../../utils/api";
import {
  ADD_ITEM_LOOKUP,
  ADD_ITEM_LOOKUP_BULK,
  GENERAL_AREA_URL,
  SPECIFIC_AREA_URL,
} from "../../../../utils/endpoints";
import { Toast } from "../../../../components/Toast";
import { useParams } from "react-router-dom";
import {
  NO_OPTIONS_MESSAGE,
  REQUIRED_FIELD,
} from "../../../../utils/constants";
import { ItemSchemForDetail } from "../../ItemSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { selectStyles } from "../../../../utils/styles";

import Select from "react-select";
import { lookUps } from "../..";

export default function ModalArea({
  isOpen,
  setIsOpen,
  setDataTable,
  setDataGeneralArea,
  setDataSpecificArea,
}) {
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(ItemSchemForDetail),
  });

  const [generalAreaOptions, setGeneralAreaOptions] = useState([]);
  const [specificAreaOptions, setspecificAreaOptions] = useState([]);

  const { addToast } = Toast();
  const { table } = useParams();

  const code_general = watch("code_general");
  const name_general = watch("name_general");
  const area_general = watch("area_general");

  const code_specific = watch("code_specific");
  const name_specific = watch("name_specific");
  const area_specific = watch("area_specific");

  const code_detailed = watch("code_detailed");
  const name_detailed = watch("name_detailed");

  async function getOptions(url, parentId = null) {
    try {
      const response = await api.get(
        parentId
          ? `${url}?ParentId=${parentId}&OnlyActive=true`
          : `${url}?OnlyActive=true`
      );
      const data = response.data.map((option) => ({
        value: option.id,
        label: option.name,
      }));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async function getGeneralAreaOptions(parentId = null) {
    getOptions(GENERAL_AREA_URL, parentId)
      .then((res) => setGeneralAreaOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getSpecificAreaOptions(parentId = null) {
    getOptions(SPECIFIC_AREA_URL, parentId)
      .then((res) => setspecificAreaOptions(res))
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  useEffect(
    () => {
      getGeneralAreaOptions();
      getSpecificAreaOptions();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (area_general) {
        setValue("specificArea", undefined);
        getSpecificAreaOptions(area_general.value);
      } else {
        setValue("specificArea", undefined);
        getSpecificAreaOptions();
      }
    },
    // eslint-disable-next-line
    [area_general]
  );

  const onSubmit = async (data) => {
    if (
      data.area_general?.value === undefined &&
      (data.name_general === "" || data.code_general === "")
    )
      return addToast({ title: REQUIRED_FIELD, status: "error" });

    if (
      data.area_specific?.value === undefined &&
      (data.name_specific === "" || data.code_specific === "")
    )
      return addToast({ title: REQUIRED_FIELD, status: "error" });

    let bodyRequest = {};
    let url = null;

    if (!area_general?.value && !area_specific?.value) {
      bodyRequest = {
        listLookupsRequest: [
          {
            entityType: "CourseGeneralArea",
            code: data.code_general,
            name: data.name_general,
            order: null,
          },
          {
            entityType: "CourseSpecificArea",
            code: data.code_specific,
            name: data.name_specific,
            order: null,
          },
          {
            entityType: "CourseDetailedArea",
            code: data.code_detailed,
            name: data.name_detailed,
            order: null,
          },
        ],
      };
      url = ADD_ITEM_LOOKUP_BULK;
    } else if (
      (!area_general?.value && area_specific?.value) ||
      (area_general?.value && area_specific?.value)
    ) {
      bodyRequest = {
        entityType: "CourseDetailedArea",
        code: data.code_detailed,
        name: data.name_detailed,
        order: null,
        parentId: area_specific.value,
      };
      url = ADD_ITEM_LOOKUP;
    } else if (area_general?.value && !area_specific?.value) {
      bodyRequest = {
        listLookupsRequest: [
          {
            entityType: "CourseSpecificArea",
            code: data.code_specific,
            name: data.name_specific,
            order: null,
            parentId: area_general.value,
          },
          {
            entityType: "CourseDetailedArea",
            code: data.code_detailed,
            name: data.name_detailed,
            order: null,
          },
        ],
      };
      url = ADD_ITEM_LOOKUP_BULK;
    }

    try {
      await api.post(url, bodyRequest);

      addToast({
        status: "success",
        title: "Item adicionado com sucesso",
      });

      const responseGeneralArea = await api.get(GENERAL_AREA_URL, {
        OnlyActive: false,
        PageSize: 2500,
      });
      setDataGeneralArea(responseGeneralArea.data);

      const responseSpecificArea = await api.get(SPECIFIC_AREA_URL, {
        OnlyActive: false,
        PageSize: 2500,
      });
      setDataSpecificArea(responseSpecificArea.data);

      const response = await lookUps[table - 1].getFunction({
        OnlyActive: false,
        PageSize: 2500,
      });
      setDataTable(response);
      setIsOpen(false);
      reset();
    } catch (e) {
      addToast({
        status: "error",
        title: "Ocorreu um erro ao adicionar item",
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      size="3xl"
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <ModalHeader>Inserir novo item</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Stack spacing={5}>
              <Box>
                <Text mb="5" fontWeight="bold">
                  Área de Formação CITE (ISCED) 2013 - Geral
                </Text>

                <HStack gap={5}>
                  <FormControl isInvalid={!!errors.code_general}>
                    <Label title="Código" isRequired />
                    <Input
                      value={code_general}
                      {...register("code_general")}
                      size="md"
                      isDisabled={!!area_general?.value}
                    />
                    <FormErrorMessage>
                      {errors.code_general && <>{REQUIRED_FIELD}</>}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!errors.name_general}>
                    <Label title="Nome" isRequired />
                    <Input
                      value={name_general}
                      {...register("name_general")}
                      size="md"
                      isDisabled={!!area_general?.value}
                    />
                    <FormErrorMessage>
                      {errors.name_general && <>{REQUIRED_FIELD}</>}
                    </FormErrorMessage>
                  </FormControl>

                  <Text
                    textTransform="uppercase"
                    fontWeight="bold"
                    fontSize="sm"
                  >
                    Ou
                  </Text>

                  <FormControl>
                    <Label title="Seleccionar" />

                    <Controller
                      control={control}
                      name="area_general"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          placeholder="Seleccionar"
                          options={generalAreaOptions}
                          onChange={onChange}
                          value={value || ""}
                          styles={selectStyles}
                          isClearable={true}
                          noOptionsMessage={NO_OPTIONS_MESSAGE}
                          isDisabled={!!code_general || !!name_general}
                        />
                      )}
                    />
                  </FormControl>
                </HStack>
              </Box>

              <Box>
                <Text mb="5" fontWeight="bold">
                  Área de Formação CITE (ISCED) 2013 - Específica
                </Text>

                <HStack gap={5}>
                  <FormControl isInvalid={!!errors.code_specific}>
                    <Label title="Código" isRequired />
                    <Input
                      value={code_specific}
                      {...register("code_specific")}
                      size="md"
                      isDisabled={!!area_specific?.value}
                    />
                    <FormErrorMessage>
                      {errors.code_specific && <>{REQUIRED_FIELD}</>}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!errors.name_specific}>
                    <Label title="Nome" isRequired />
                    <Input
                      value={name_specific}
                      {...register("name_specific")}
                      size="md"
                      isDisabled={!!area_specific?.value}
                    />
                    <FormErrorMessage>
                      {errors.name_specific && <>{REQUIRED_FIELD}</>}
                    </FormErrorMessage>
                  </FormControl>

                  <Text
                    textTransform="uppercase"
                    fontWeight="bold"
                    fontSize="sm"
                  >
                    Ou
                  </Text>

                  <FormControl>
                    <Label title="Seleccionar" />

                    <Controller
                      control={control}
                      name="area_specific"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          placeholder="Seleccionar"
                          options={specificAreaOptions}
                          onChange={onChange}
                          value={value || ""}
                          styles={selectStyles}
                          isClearable={true}
                          noOptionsMessage={NO_OPTIONS_MESSAGE}
                          isDisabled={
                            !area_general?.value ||
                            !!code_specific ||
                            !!name_specific
                          }
                        />
                      )}
                    />
                  </FormControl>
                </HStack>
              </Box>

              <Box>
                <Text mb="5" fontWeight="bold">
                  Área de Formação CITE (ISCED) 2013 - Detalhada
                </Text>

                <HStack gap={5}>
                  <FormControl isInvalid={!!errors.code_detailed}>
                    <Label title="Código" isRequired />
                    <Input
                      value={code_detailed}
                      {...register("code_detailed")}
                      size="md"
                    />
                    <FormErrorMessage>
                      {errors.code_detailed && <>{REQUIRED_FIELD}</>}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!errors.name_detailed}>
                    <Label title="Nome" isRequired />
                    <Input
                      value={name_detailed}
                      {...register("name_detailed")}
                      size="md"
                    />
                    <FormErrorMessage>
                      {errors.name_detailed && <>{REQUIRED_FIELD}</>}
                    </FormErrorMessage>
                  </FormControl>
                </HStack>
              </Box>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              mr={4}
              variant="link"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Cancelar
            </Button>
            <Button type="submit">Inserir</Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}
