import {
  Box,
  Button,
  Checkbox,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Label from "../../../components/Label";
import { selectStyles } from "../../../utils/styles";
import {
  HAS_LEGAL_EQUIVALENCE,
  HAS_LEGAL_PROOF,
  NO_OPTIONS_MESSAGE,
  REQUIRED_FIELD,
} from "../../../utils/constants";
import Select from "react-select";
import { useContext, useEffect, useState } from "react";
import { Toast } from "../../../components/Toast";
import {
  ACADEMIC_YEAR_URL,
  CLASS_LETTER_URL,
  CLASS_MODALITY_URL,
  CLASS_SHIFT_URL,
  CLASS_URL,
  COURSE_URL,
  ENROLLMENT_STATUS_URL,
  SCHOOL_URL,
} from "../../../utils/endpoints";
import api from "../../../utils/api";
import { AuthContext } from "../../../provider/AuthProvider";

function EnrollmentModal({
  schoolBondRequests,
  schoolBondRequestsData,
  setSchoolBondRequests,
  setSchoolBondRequestsData,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [academicYear, setAcademicYear] = useState();
  const [school, setSchool] = useState();
  const [course, setCourse] = useState();
  const [studentClass, setStudentClass] = useState();
  const [classModality, setClassModality] = useState();
  const [classShift, setClassShift] = useState();
  const [classLetter, setClassLetter] = useState();
  const [classFullName, setClassFullName] = useState();
  const [studentEnrollmentStatus, setStudentEnrollmentStatus] = useState();
  const [academicYearOptions, setAcademicYearOptions] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [classOptions, setClassOptions] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [classModalityOptions, setClassModalityOptions] = useState([]);
  const [classShiftOptions, setClassShiftOptions] = useState([]);
  const [classLetterOptions, setClassLetterOptions] = useState([]);
  const [studentEnrollmentStatusOptions, setStudentEnrollmentStatusOptions] =
    useState([]);
  const [hasLegalEquivalence, setHasLegalEquivalence] = useState(false);
  const [hasLegalProof, setHasLegalProof] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { addToast } = Toast();
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  useEffect(() => {
    if (isOpen) {
      setAcademicYear("");
      if (!user?.schoolId) {
        setSchool("");
      }
      setCourse("");
      setStudentClass("");
      setClassModality("");
      setClassShift("");
      setClassLetter("");
    }
  }, [isOpen]);

  async function getAcademicYears() {
    api
      .get(ACADEMIC_YEAR_URL)
      .then((res) => {
        const data = res.data.result;
        setAcademicYearOptions(
          data
            .filter((i) => i.isActive)
            .map((i) => ({
              value: i.id,
              label: i.year,
              isBaseYear: i.isBaseYear,
            }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getSchools() {
    api
      .get(`${SCHOOL_URL}?isValidated=true&isActive=true`)
      .then((res) => {
        const data = res.data.result;
        setSchoolOptions(
          data.map((i) => ({
            value: i.id,
            label: i.name,
          }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getCourses() {
    api
      .get(
        `${COURSE_URL}?isValidated=true&isActive=true&schoolId=${school.value}&AcademicYearId=${academicYear.value}`
      )
      .then((res) => {
        const data = res.data.result;
        setCourseOptions(
          data.map((i) => ({
            value: i.id,
            label: i.name,
            className: i.className,
            courseTechnicalName: i.courseTechnical?.name,
            courseTechnicalId: i.courseTechnical?.id,
            educationalSubsystemName: i.educationalSubsystem.name,
            educationalSubsystemId: i.educationalSubsystem.id,
            educationalCycleName: i.educationalCycle.name,
            educationalCycleId: i.educationalCycle.id,
          }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getClasses() {
    api
      .get(
        `${CLASS_URL}?OnlyActive=true&ParentId=${course?.educationalCycleId}`
      )
      .then((res) => {
        const data = res.data;
        if (academicYear.isBaseYear) {
          setClassOptions(
            data.map((i) => ({
              value: i.id,
              label: i.name,
              code: i.code,
              previousClassCode: i.previousClassCode,
            }))
          );
        } else if (hasLegalProof || hasLegalEquivalence) {
          setClassOptions(
            data.map((i) => ({
              value: i.id,
              label: i.name,
              code: i.code,
              previousClassCode: i.previousClassCode,
            }))
          );
          setStudentClass("");
        } else {
          if (schoolBondRequestsData.length > 0) {
            const lastSchoolBond =
              schoolBondRequestsData[schoolBondRequestsData.length - 1];
            const lastCourse =
              lastSchoolBond.courseBonded.value ||
              lastSchoolBond.courseBonded.id;
            const lastEducationCycle =
              lastSchoolBond.courseEducationalCycleBonded.id;
            const lastClassCode = lastSchoolBond.classBonded.code;
            const lastClassId =
              lastSchoolBond.classBonded.value || lastSchoolBond.classBonded.id;
            const lastBondResult =
              lastSchoolBond.schoolBondResultBonded?.value ||
              lastSchoolBond.schoolBondResultBonded?.id;
            if (
              lastCourse === course.value &&
              lastEducationCycle === course.educationalCycleId
            ) {
              if (lastBondResult && lastBondResult === 1) {
                if (lastClassId !== 18 || lastClassId !== 22) {
                  setClassOptions([
                    data
                      .map((i) => ({
                        value: i.id,
                        label: i.name,
                        code: i.code,
                        previousClassCode: i.previousClassCode,
                      }))
                      .filter((i) => i.previousClassCode === lastClassCode)[0],
                  ]);
                  setStudentClass(
                    data
                      .map((i) => ({
                        value: i.id,
                        label: i.name,
                        code: i.code,
                        previousClassCode: i.previousClassCode,
                      }))
                      .filter((i) => i.previousClassCode === lastClassCode)[0]
                  );
                } else {
                  setClassOptions([
                    data.map((i) => ({
                      value: i.id,
                      label: i.name,
                      code: i.code,
                      previousClassCode: i.previousClassCode,
                    }))[data.length - 1],
                  ]);
                  setStudentClass(
                    data.map((i) => ({
                      value: i.id,
                      label: i.name,
                      code: i.code,
                      previousClassCode: i.previousClassCode,
                    }))[data.length - 1]
                  );
                }
              } else {
                setClassOptions([
                  data
                    .map((i) => ({
                      value: i.id,
                      label: i.name,
                      code: i.code,
                      previousClassCode: i.previousClassCode,
                    }))
                    .filter((i) => i.code === lastClassCode)[0],
                ]);
                setStudentClass(
                  data
                    .map((i) => ({
                      value: i.id,
                      label: i.name,
                      code: i.code,
                      previousClassCode: i.previousClassCode,
                    }))
                    .filter((i) => i.code === lastClassCode)[0]
                );
              }
            } else {
              setClassOptions([
                data.map((i) => ({
                  value: i.id,
                  label: i.name,
                  code: i.code,
                  previousClassCode: i.previousClassCode,
                }))[0],
              ]);
              setStudentClass(
                data.map((i) => ({
                  value: i.id,
                  label: i.name,
                  code: i.code,
                  previousClassCode: i.previousClassCode,
                }))[0]
              );
            }
          } else {
            setClassOptions([
              data.map((i) => ({
                value: i.id,
                label: i.name,
                code: i.code,
                previousClassCode: i.previousClassCode,
              }))[0],
            ]);
            setStudentClass(
              data.map((i) => ({
                value: i.id,
                label: i.name,
                code: i.code,
                previousClassCode: i.previousClassCode,
              }))[0]
            );
          }
        }
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getClassModalities() {
    api
      .get(`${CLASS_MODALITY_URL}?OnlyActive=true`)
      .then((res) => {
        const data = res.data;
        setClassModalityOptions(
          data.map((i) => ({
            value: i.id,
            label: i.name,
          }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getClassShifts() {
    api
      .get(`${CLASS_SHIFT_URL}?OnlyActive=true`)
      .then((res) => {
        const data = res.data;
        setClassShiftOptions(
          data.map((i) => ({
            value: i.id,
            label: i.name,
          }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getClassLetters() {
    api
      .get(`${CLASS_LETTER_URL}?OnlyActive=true`)
      .then((res) => {
        const data = res.data;
        setClassLetterOptions(
          data.map((i) => ({
            value: i.id,
            label: i.name,
          }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getStudentEnrollmentStatus() {
    api
      .get(`${ENROLLMENT_STATUS_URL}?OnlyActive=true`)
      .then((res) => {
        const data = res.data;
        setStudentEnrollmentStatusOptions(
          data.map((i) => ({
            value: i.id,
            label: i.name,
          }))
        );
        setStudentEnrollmentStatus(
          data.map((i) => ({
            value: i.id,
            label: i.name,
          }))[0]
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  const onChangeAcademicYear = (value) => {
    setAcademicYear(value);
  };

  const onChangeSchool = (value) => {
    setSchool(value);
  };

  const onChangeCourse = (value) => {
    setCourse(value);
  };

  const onChangeClass = (value) => {
    setStudentClass(value);
  };

  const onChangeClassModality = (value) => {
    setClassModality(value);
  };

  const onChangeClassShift = (value) => {
    setClassShift(value);
  };

  const onChangeClassLetter = (value) => {
    setClassLetter(value);
  };

  const onChangeSchoolEnrollmentStatus = (value) => {
    setStudentEnrollmentStatus(value);
  };

  const handleCheckboxHasLegalEquivalence = () => {
    setHasLegalEquivalence(!hasLegalEquivalence);
  };

  const handleCheckboxHasLegalProof = () => {
    setHasLegalProof(!hasLegalProof);
  };

  useEffect(() => {
    getAcademicYears();
    getSchools();
    getClassModalities();
    getClassShifts();
    getClassLetters();
    getStudentEnrollmentStatus();
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (user?.schoolId && schoolOptions.length > 0) {
      setSchool(
        schoolOptions.filter((item) => item.value === user?.schoolId)[0]
      );
    }
  }, [user, schoolOptions]);

  useEffect(() => {
    if (academicYear?.value) {
      if (!user?.schoolId) {
        setSchool("");
      }
      setCourse("");
      setStudentClass("");
    }
  }, [academicYear]);

  useEffect(() => {
    if (academicYear?.value && school?.value) {
      getCourses();
      setCourse("");
      setStudentClass("");
    }
  }, [academicYear, school]);

  useEffect(() => {
    if (course?.value) {
      getClasses();
      setStudentClass("");
    }
  }, [course]);

  useEffect(() => {
    if (course?.value) {
      getClasses();
    }
  }, [hasLegalEquivalence, hasLegalProof]);

  useEffect(() => {
    if (course?.className && studentClass && classLetter && classShift) {
      let courseClassName = course?.className;
      let studentClassNumber = studentClass.label.split("ª")[0];
      let classLetterLabel = classLetter.label;
      let classShiftLetter = classShift.label[0];

      setClassFullName(
        `${courseClassName}${studentClassNumber}${classLetterLabel}${classShiftLetter}`
      );
    } else {
      setClassFullName("");
    }
  }, [course, studentClass, classLetter, classShift]);

  const handleSchoolBondRequests = () => {
    if (
      !academicYear?.value ||
      !school?.value ||
      !course?.value ||
      !studentClass?.value ||
      !classModality?.value ||
      !classShift?.value ||
      !classLetter?.value ||
      !studentEnrollmentStatus?.value
    ) {
      addToast({ title: REQUIRED_FIELD, status: "error" });
    } else if (
      schoolBondRequests.filter(
        (item) =>
          item.academicYearBondedId === academicYear.value &&
          ((item.studentEnrollmentStatusBondedId === 1 &&
            studentEnrollmentStatus.value === 1) ||
            (item.studentEnrollmentStatusBondedId === 2 &&
              studentEnrollmentStatus.value === 2) ||
            (item.studentEnrollmentStatusBondedId === 1 &&
              studentEnrollmentStatus.value === 2) ||
            (item.studentEnrollmentStatusBondedId === 2 &&
              studentEnrollmentStatus.value === 1) ||
            item.studentEnrollmentStatusBondedId === 1 ||
            item.studentEnrollmentStatusBondedId === 2)
      ).length > 0
    ) {
      addToast({
        title: `Já existe uma matrícula para o ano lectivo ${academicYear.label}`,
        status: "error",
      });
    } else if (
      schoolBondRequestsData.length > 0 &&
      schoolBondRequestsData[schoolBondRequestsData.length - 1]
        .schoolBondResultBonded?.id === undefined &&
      schoolBondRequestsData[schoolBondRequestsData.length - 1]
        .schoolBondResultBonded?.value === undefined &&
      schoolBondRequestsData[schoolBondRequestsData.length - 1]
        .studentEnrollmentStatusBonded?.id !== 3 &&
      schoolBondRequestsData[schoolBondRequestsData.length - 1]
        .studentEnrollmentStatusBonded?.value !== 3 &&
      schoolBondRequestsData[schoolBondRequestsData.length - 1]
        .studentEnrollmentStatusBonded?.id !== 4 &&
      schoolBondRequestsData[schoolBondRequestsData.length - 1]
        .studentEnrollmentStatusBonded?.value !== 4
    ) {
      addToast({
        title: `A matrícula anterior não tem resultado associado`,
        status: "error",
      });
    } else {
      const newSchoolBondRequest = {
        academicYearBondedId: academicYear?.value,
        schoolBondedId: school?.value,
        courseBondedId: course?.value,
        courseTechnicalBondedId: course.courseTechnicalId,
        courseEducationalSubsystemBondedId: course.educationalSubsystemId,
        courseEducationalCycleBondedId: course.educationalCycleId,
        studentEnrollmentStatusBondedId: studentEnrollmentStatus?.value,
        classBondedId: studentClass?.value,
        classModalityBondedId: classModality?.value,
        classShiftBondedId: classShift?.value,
        classLetterBondedId: classLetter?.value,
        classFullName: classFullName,
        hasLegalEquivalence: hasLegalEquivalence ? hasLegalEquivalence : null,
        hasLegalProof: hasLegalProof ? hasLegalProof : null,
      };

      const newSchoolBondRequestData = {
        academicYearBonded: academicYear,
        schoolBonded: school,
        courseBonded: course,
        courseTechnicalBonded: {
          id: course.courseTechnicalId,
          name: course.courseTechnicalName,
        },
        courseEducationalSubsystemBonded: {
          id: course.educationalSubsystemId,
          name: course.educationalSubsystemName,
        },
        courseEducationalCycleBonded: {
          id: course.educationalCycleId,
          name: course.educationalCycleName,
        },
        studentEnrollmentStatusBonded: studentEnrollmentStatus,
        classBonded: studentClass,
        classModalityBonded: classModality,
        classShiftBonded: classShift,
        classLetterBonded: classLetter,
        classFullName: classFullName,
        hasLegalEquivalence: hasLegalEquivalence ? hasLegalEquivalence : null,
        hasLegalProof: hasLegalProof ? hasLegalProof : null,
      };

      setSchoolBondRequests((prevState) => [
        ...prevState,
        newSchoolBondRequest,
      ]);
      setSchoolBondRequestsData((prevState) => [
        ...prevState,
        newSchoolBondRequestData,
      ]);

      onClose();
    }
  };

  return (
    <Box mb={3}>
      <Button variant="secondary" onClick={onOpen}>
        Inserir Dados Escolares
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Inserir Dados Escolares</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoaded && (
              <Stack gap={5}>
                <Box>
                  <Label title="Ano Lectivo" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={academicYearOptions}
                    onChange={onChangeAcademicYear}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                </Box>
                <Box>
                  <Label title="Escola" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={schoolOptions}
                    onChange={onChangeSchool}
                    value={school || ""}
                    isDisabled={!academicYear?.value || user?.schoolId}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                </Box>
                <Box>
                  <Label title="Curso" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={courseOptions}
                    onChange={onChangeCourse}
                    value={course || ""}
                    isDisabled={!academicYear?.value || !school?.value}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                </Box>
                <Box>
                  <Label title="Área de Formação do INFQE" isRequired />
                  <Input
                    value={course?.value ? course?.courseTechnicalName : ""}
                    isDisabled={true}
                  />
                </Box>
                <Box>
                  <Label title="Subsistema de Ensino" isRequired />
                  <Input
                    value={
                      course?.value ? course?.educationalSubsystemName : ""
                    }
                    isDisabled
                  />
                </Box>
                <Box>
                  <Label title="Ciclo de Ensino" isRequired />
                  <Input
                    value={course?.value ? course?.educationalCycleName : ""}
                    isDisabled
                  />
                </Box>
                <Box>
                  <Label title="Classe" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={classOptions}
                    onChange={onChangeClass}
                    value={studentClass || ""}
                    isDisabled={!course?.value}
                    isSearchable={false}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                  {academicYear?.value && !academicYear.isBaseYear && (
                    <Box mt={2}>
                      <Stack spacing={2}>
                        <Text fontSize="sm" fontWeight="700">
                          Caso queira matricular o aluno numa outra classe,
                          indique o motivo:
                        </Text>
                        <Checkbox
                          onChange={handleCheckboxHasLegalEquivalence}
                          isDisabled={hasLegalProof}
                        >
                          <Text fontSize="sm">{HAS_LEGAL_EQUIVALENCE}</Text>
                        </Checkbox>
                        <Checkbox
                          onChange={handleCheckboxHasLegalProof}
                          isDisabled={hasLegalEquivalence}
                        >
                          <Text fontSize="sm">{HAS_LEGAL_PROOF}</Text>
                        </Checkbox>
                      </Stack>
                    </Box>
                  )}
                </Box>
                <Box>
                  <Label title="Modalidade" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={classModalityOptions}
                    onChange={onChangeClassModality}
                    value={classModality || ""}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                </Box>
                <Box>
                  <Label title="Turno" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={classShiftOptions}
                    onChange={onChangeClassShift}
                    value={classShift || ""}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                </Box>
                <Box>
                  <Label title="Letra da Turma" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={classLetterOptions}
                    onChange={onChangeClassLetter}
                    value={classLetter || ""}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                </Box>
                <Box>
                  <Label title="Turma" isRequired />
                  <Input value={classFullName} isDisabled />
                </Box>
                <Box>
                  <Label title="Estado da matrícula" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={studentEnrollmentStatusOptions}
                    defaultValue={studentEnrollmentStatusOptions[0]}
                    onChange={onChangeSchoolEnrollmentStatus}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                </Box>
              </Stack>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleSchoolBondRequests} mr={4}>
              Inserir
            </Button>
            <Button variant="link" onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default EnrollmentModal;
