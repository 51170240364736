import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import Label from "../../../components/Label";
import { selectStyles } from "../../../utils/styles";
import { NO_OPTIONS_MESSAGE, REQUIRED_FIELD } from "../../../utils/constants";
import Select from "react-select";
import { useContext, useEffect, useState } from "react";
import { Toast } from "../../../components/Toast";
import {
  ACADEMIC_YEAR_URL,
  COURSE_URL,
  SCHOOL_URL,
  SUBJECT_URL,
} from "../../../utils/endpoints";
import api from "../../../utils/api";
import { AuthContext } from "../../../provider/AuthProvider";

function SubjectsModal({
  contractualBondRequests,
  setContractualBondRequests,
  contractualBondRequestsData,
  setContractualBondRequestsData,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [academicYear, setAcademicYear] = useState();
  const [school, setSchool] = useState();
  const [courses, setCourses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [academicYearOptions, setAcademicYearOptions] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const { addToast } = Toast();
  const { auth } = useContext(AuthContext);
  const user = auth.user;

  useEffect(() => {
    if (isOpen) {
      setAcademicYear("");
      if (!user?.schoolId) {
        setSchool("");
      }
      setCourses([]);
      setSubjects([]);
      setSubjectOptions([]);
    }
  }, [isOpen]);

  async function getAcademicYears() {
    api
      .get(ACADEMIC_YEAR_URL)
      .then((res) => {
        const data = res.data.result;
        setAcademicYearOptions(
          data
            .filter((i) => i.isActive)
            .map((i) => ({
              value: i.id,
              label: i.year,
            }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getSchools() {
    api
      .get(`${SCHOOL_URL}?isValidated=true&isActive=true`)
      .then((res) => {
        const data = res.data.result;
        setSchoolOptions(
          data.map((i) => ({
            value: i.id,
            label: i.name,
          }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getCourses() {
    api
      .get(
        `${COURSE_URL}?isValidated=true&isActive=true&schoolId=${school.value}&AcademicYearId=${academicYear.value}`
      )
      .then((res) => {
        const data = res.data.result;
        setCourseOptions(
          data.map((i) => ({
            value: i.id,
            label: i.name,
          }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getSubjects() {
    if (courses.length > 0) {
      const requests = courses.map((course) => {
        return api.get(
          `${SUBJECT_URL}?isActive=true&CourseId=${course?.value}`
        );
      });

      try {
        const responses = await Promise.all(requests);
        const res = responses.flatMap((res) => {
          const data = res.data.result;
          return data.map((i) => ({
            value: i.id,
            label: i.name,
          }));
        });
        setSubjectOptions(
          Array.from(new Set(res.map((subject) => subject.value))).map(
            (value) => res.find((subject) => subject.value === value)
          )
        );
      } catch (error) {
        addToast({ title: error.message, status: "error" });
      }
    }
  }

  const onChangeAcademicYear = (value) => {
    setAcademicYear(value);
  };

  const onChangeSchool = (value) => {
    setSchool(value);
  };

  const onChangeCourses = (value) => {
    setCourses(value);
    setSubjects([]);
  };

  const onChangeSubjects = (value) => {
    setSubjects(value);
  };

  useEffect(() => {
    getAcademicYears();
    getSchools();
  }, []);

  useEffect(() => {
    if (academicYearOptions.length > 0 && schoolOptions.length > 0) {
      setIsLoaded(true);
    }
  }, [academicYearOptions, schoolOptions]);

  useEffect(() => {
    if (user?.schoolId && schoolOptions.length > 0) {
      setSchool(
        schoolOptions.filter((item) => item.value === user?.schoolId)[0]
      );
    }
  }, [user, schoolOptions]);

  useEffect(() => {
    if (academicYear?.value) {
      if (!user?.schoolId) {
        setSchool("");
      }
      setCourses([]);
    }
  }, [academicYear]);

  useEffect(() => {
    if (academicYear?.value && school?.value) {
      getCourses();
      setCourses([]);
    }
  }, [academicYear, school]);

  useEffect(() => {
    if (courses.length > 0) {
      getSubjects();
    }
  }, [courses]);

  const handleCoursesSubjectsBound = () => {
    if (
      !academicYear?.value ||
      !school?.value ||
      !courses?.length > 0 ||
      !subjects?.length > 0
    ) {
      addToast({ title: REQUIRED_FIELD, status: "error" });
    } else {
      setContractualBondRequests((prevState) =>
        prevState.map((item) => {
          if (
            item.academicYearId === academicYear?.value &&
            item.schoolId === school?.value
          ) {
            return {
              ...item,
              coursesBound: courses?.map((i) => i.value),
              subjectsBound: subjects?.map((i) => i.value),
            };
          }
          return item;
        })
      );
      setContractualBondRequestsData((prevState) =>
        prevState.map((item) => {
          if (
            (item.academicYearBonded?.year ||
              item.academicYearBonded?.value === academicYear?.value) &&
            (item.schoolBonded?.id ||
              item.schoolBonded?.value === school?.value)
          ) {
            return {
              ...item,
              coursesBound: courses?.map((i) => i),
              subjectsBound: subjects?.map((i) => i),
            };
          }
          return item;
        })
      );
      onClose();
    }
  };

  return (
    <Box mb={3}>
      <Button
        variant="secondary"
        onClick={onOpen}
        isDisabled={
          !contractualBondRequests.length > 0 &&
          !contractualBondRequestsData.length > 0
        }
      >
        Associar Cursos e Disciplinas
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Associar Cursos e Disciplinas</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoaded && (
              <Stack gap={5}>
                <Box>
                  <Label title="Ano Lectivo" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={academicYearOptions?.filter((item) =>
                      contractualBondRequests
                        .map((item) => item.academicYearId)
                        .includes(item.value)
                    )}
                    onChange={onChangeAcademicYear}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                </Box>
                <Box>
                  <Label title="Escola" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={schoolOptions?.filter((item) =>
                      contractualBondRequests
                        .map((item) => item.schoolId)
                        .includes(item.value)
                    )}
                    onChange={onChangeSchool}
                    value={school || ""}
                    isDisabled={user?.schoolId}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                </Box>
                <Box>
                  <Label title="Cursos" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={courseOptions}
                    onChange={onChangeCourses}
                    value={courses || ""}
                    isDisabled={!academicYear?.value || !school?.value}
                    styles={selectStyles}
                    isMulti
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                </Box>
                <Box>
                  <Label title="Disciplinas" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={subjectOptions}
                    onChange={onChangeSubjects}
                    value={subjects || ""}
                    isDisabled={!courses.length > 0}
                    styles={selectStyles}
                    isMulti
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                </Box>
              </Stack>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleCoursesSubjectsBound} mr={4}>
              Associar
            </Button>
            <Button variant="link" onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default SubjectsModal;
