import { useState } from "react";
import { Link } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { BsTrash } from "react-icons/bs";
import Table from "../../../components/Table";
import { NO_RESULTS } from "../../../utils/constants";
import { formStyles } from "../../../utils/styles";

export default function TableSchools({
  setDataTable,
  dataTable,
  academicYear,
  id,
}) {
  const [item, setItem] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const columns = [
    {
      Header: "Código da Escola",
      accessor: "academicYearForSchool.school.code",
    },
    {
      Header: "Designação da Escola",
      accessor: "academicYearForSchool.school.name",
    },
    {
      Header: "Vagas",
      disableSortBy: true,
      Cell: (item) => {
        return (
          <NumberInput
            defaultValue={item.row.original?.capacity}
            isDisabled={true}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        );
      },
    },
    {
      Header: "Candidaturas",
      disableSortBy: true,
      Cell: (item) => {
        return (
          <NumberInput
            defaultValue={item.row.original?.applications}
            isDisabled={true}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        );
      },
    },
    {
      Header: "Desassociar",
      accessor: "delete",
      disableSortBy: true,
      Cell: (item) => {
        return (
          <Center>
            <Link>
              <BsTrash
                onClick={() => {
                  onOpen();
                  setItem(item.row.original.academicYearForSchool.school);
                }}
              />
            </Link>
          </Center>
        );
      },
    },
  ];

  return (
    <>
      {dataTable ? (
        dataTable.length > 0 ? (
          <>
            <Table columns={columns} data={dataTable} isPaginated={false} />
            <AlertDialog isOpen={isOpen} onClose={onClose}>
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Remover Escola
                  </AlertDialogHeader>

                  <AlertDialogBody>Tem a certeza?</AlertDialogBody>

                  <AlertDialogFooter>
                    <Button
                      onClick={() => {
                        setDataTable(
                          dataTable.filter(
                            (i) => i.academicYearForSchool.school.id !== item.id
                          )
                        );
                        setItem(null);
                        onClose();
                      }}
                      mr={4}
                    >
                      Remover
                    </Button>
                    <Button variant="link" onClick={onClose}>
                      Cancelar
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </>
        ) : (
          <Box sx={formStyles}>
            <Text>{NO_RESULTS}</Text>
          </Box>
        )
      ) : (
        <Spinner />
      )}
    </>
  );
}
