import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import { DIALOG_MESSAGE } from "../../../utils/constants";

function ResetPassword({ resetPassword, isDisabled }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex>
      <Button variant="secondary" onClick={onOpen} isDisabled={isDisabled}>
        Enviar reposição de senha
      </Button>

      <AlertDialog isOpen={isOpen} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Enviar reposição de senha
            </AlertDialogHeader>

            <AlertDialogBody>{DIALOG_MESSAGE}</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={resetPassword} mr={4}>
                Enviar
              </Button>
              <Button variant="link" onClick={onClose}>
                Cancelar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
}

export default ResetPassword;
