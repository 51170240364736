import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  HStack,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { BsQuestionCircle } from "react-icons/bs";
import { DIALOG_MESSAGE } from "../../../utils/constants";
import { useState } from "react";
import Alert from "../../../components/Alert";
import api from "../../../utils/api";
import { useNavigate, useParams } from "react-router-dom";
import { Toast } from "../../../components/Toast";
import {
  DISABLE_SCHOOL_URL,
  ENABLE_SCHOOL_URL,
} from "../../../utils/endpoints";

function Delete({ deleteSchool, isActive }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isOpenArchive, setIsOpenArchive] = useState(false);
  const [isOpenDearchive, setIsOpenDearchive] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { addToast } = Toast();

  const handleArchiveSchool = async () => {
    try {
      await api.patch(DISABLE_SCHOOL_URL, {
        id,
      });
      navigate(`/escolas/gerir/${id}`);
      addToast({
        title: "Escola arquivada com sucesso",
        status: "success",
      });
    } catch (e) {
      let errors = e.response.data.errors;
      for (let err in errors) {
        addToast({ title: errors[err], status: "error" });
      }
    }
  };

  const handleDearchiveSchool = async () => {
    try {
      await api.patch(ENABLE_SCHOOL_URL, {
        id,
      });
      navigate(`/escolas/gerir/${id}`);
      addToast({
        title: "Escola desarquivada com sucesso",
        status: "success",
      });
    } catch (e) {
      let errors = e.response.data.errors;
      for (let err in errors) {
        addToast({ title: errors[err], status: "error" });
      }
    }
  };

  return (
    <Flex direction="row" gap={3}>
      {isActive ? (
        <HStack>
          <Button
            variant="secondary"
            onClick={() => {
              setIsOpenArchive(true);
            }}
          >
            Arquivar
          </Button>
          <Tooltip hasArrow label="Esta acção arquiva esta escola no sistema">
            <Flex alignItems="center">
              <BsQuestionCircle />
            </Flex>
          </Tooltip>
        </HStack>
      ) : (
        <HStack>
          <Button
            variant="link"
            onClick={() => {
              setIsOpenDearchive(true);
            }}
          >
            Desarquivar
          </Button>
          <Tooltip
            hasArrow
            label="Esta acção desarquiva esta escola no sistema"
          >
            <Flex alignItems="center">
              <BsQuestionCircle />
            </Flex>
          </Tooltip>
        </HStack>
      )}

      <HStack>
        <Button variant="secondary" onClick={onOpen}>
          Excluir
        </Button>
        <Tooltip
          hasArrow
          label="Esta acção exclui, de forma permanente, esta escola do sistema"
        >
          <Flex alignItems="center">
            <BsQuestionCircle />
          </Flex>
        </Tooltip>
      </HStack>

      <Alert
        isOpen={isOpenArchive}
        setIsOpen={setIsOpenArchive}
        text="Tem a certeza que pretende arquivar a Escola?"
        title="Arquivar Escola"
        handleDelete={handleArchiveSchool}
        buttonText="Arquivar"
      />
      <Alert
        isOpen={isOpenDearchive}
        setIsOpen={setIsOpenDearchive}
        text="Tem a certeza que pretende desarquivar a Escola?"
        title="Desarquivar Escola"
        handleDelete={handleDearchiveSchool}
        buttonText="Desarquivar"
      />

      <AlertDialog isOpen={isOpen} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Excluir escola
            </AlertDialogHeader>

            <AlertDialogBody>{DIALOG_MESSAGE}</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={deleteSchool} mr={3}>
                Excluir
              </Button>
              <Button variant="secondary" onClick={onClose}>
                Cancelar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
}

export default Delete;
