import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Table from "../../../components/Table";
import { formStyles } from "../../../utils/styles";
import { NO_RESULTS } from "../../../utils/constants";
import { Link } from "react-router-dom";
import { BsTrash } from "react-icons/bs";
import { useState } from "react";

function TableLabs({ labsData, setLabsData, isDisabled }) {
  const [item, setItem] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleAddFieldOnObject = (fieldName, value, item) => {
    const updatedValue = parseInt(value);

    setLabsData((prevLabsData) => {
      return prevLabsData.map((lab) => {
        if (lab.labType === item.labType) {
          const updatedLab = {
            ...lab,
            [fieldName]: updatedValue,
          };
          updatedLab.numberOfLabsTotal =
            (fieldName === "numberOfAvailableLabs"
              ? updatedValue
              : updatedLab.numberOfAvailableLabs) +
            (fieldName === "numberOfUnavailableLabs"
              ? updatedValue
              : updatedLab.numberOfUnavailableLabs);
          return updatedLab;
        }
        return lab;
      });
    });
  };

  let columns = [
    {
      Header: "Designação",
      accessor: "labName",
    },
    {
      Header: "Funcionais",
      disableSortBy: true,
      Cell: (item) => {
        return (
          <NumberInput
            defaultValue={item.row.original?.numberOfAvailableLabs}
            min={0}
            onChange={(e) => {
              handleAddFieldOnObject(
                "numberOfAvailableLabs",
                e,
                item.row.original
              );
            }}
            isDisabled={isDisabled}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        );
      },
    },
    {
      Header: "Não funcionais",
      disableSortBy: true,
      Cell: (item) => {
        return (
          <NumberInput
            defaultValue={item.row.original?.numberOfUnavailableLabs}
            min={0}
            onChange={(e) => {
              handleAddFieldOnObject(
                "numberOfUnavailableLabs",
                e,
                item.row.original
              );
            }}
            isDisabled={isDisabled}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        );
      },
    },
    {
      Header: "Número total",
      disableSortBy: true,
      Cell: (item) => {
        return (
          <Input
            value={item.row.original?.numberOfLabsTotal}
            isDisabled={true}
          />
        );
      },
    },
    {
      Header: "Remover",
      accessor: "delete",
      disableSortBy: true,
      Cell: (item) => {
        return item.row.original.labType === 1 ? (
          <Center _hover={{ cursor: "not-allowed" }}>
            <BsTrash />
          </Center>
        ) : (
          <Center>
            <Link>
              <BsTrash
                onClick={() => {
                  onOpen();
                  setItem(item.row.original);
                }}
              />
            </Link>
          </Center>
        );
      },
    },
  ];

  if (isDisabled) {
    columns = columns.filter((column) => column.accessor !== "delete");
  }

  return (
    <>
      {labsData ? (
        labsData.length > 0 ? (
          <>
            <Table columns={columns} data={labsData} isPaginated={false} />
            <AlertDialog isOpen={isOpen} onClose={onClose}>
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Remover Infra-estrutura
                  </AlertDialogHeader>

                  <AlertDialogBody>Tem a certeza?</AlertDialogBody>

                  <AlertDialogFooter>
                    <Button
                      onClick={() => {
                        setLabsData(
                          labsData.filter((i) => i.labType !== item.labType)
                        );
                        setItem(null);
                        onClose();
                      }}
                      mr={3}
                    >
                      Remover
                    </Button>
                    <Button variant="secondary" onClick={onClose}>
                      Cancelar
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </>
        ) : (
          <Box sx={formStyles}>
            <Text>{NO_RESULTS}</Text>
          </Box>
        )
      ) : (
        <Spinner />
      )}
    </>
  );
}

export default TableLabs;
