import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import Select from "react-select";
import { NO_OPTIONS_MESSAGE } from "../../../../../utils/constants";
import { useEffect, useState } from "react";

export default function ModalAssociate({
  setIsOpen,
  isOpen,
  title,
  setDataTable,
  dataTable,
  courses,
}) {
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (isOpen) {
      setOptions(
        courses.filter(
          (obj2) => !dataTable.some((obj1) => obj1.code === obj2.code)
        )
      );
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Select
            isMulti={true}
            placeholder="Seleccionar"
            options={options}
            onChange={(values) => {
              setSelectedOptions(values);
            }}
            noOptionsMessage={NO_OPTIONS_MESSAGE}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              setDataTable([...dataTable, ...selectedOptions]);
              setIsOpen(false);
            }}
            mr={3}
          >
            Associar
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
